function Preloading() {
  return (
    <div className=" bottom-0 right-0 top-0 h-screen fixed w-full bg-gray-50 ">
      <div className="text-center text-6xl font-bold opacity-30 text-secondAccent p-3">
      </div>
    </div>
  );
}

export default Preloading;
